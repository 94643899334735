import AddressManager, { ContractType, SupportedChains } from '@kodiak-finance/address-manager'
type AddressMap = { [chainId: number]: string }

export const FACTORY_ADDRESS: AddressMap = {
  [SupportedChains.POLYGON_MUMBAI]: AddressManager.network(SupportedChains.POLYGON_MUMBAI).require(
    ContractType.UniswapV3Factory
  ),
  [SupportedChains.BERACHAIN_PRIVATE_TESTNET]: AddressManager.network(
    SupportedChains.BERACHAIN_PRIVATE_TESTNET
  ).require(ContractType.UniswapV3Factory),
  [SupportedChains.BERACHAIN_ARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_ARTIO_TESTNET).require(
    ContractType.UniswapV3Factory
  ),
  [SupportedChains.BERACHAIN_BARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_BARTIO_TESTNET).require(
    ContractType.UniswapV3Factory
  )

  // [SupportedChains.BERACHAIN_MAINNET]: AddressManager.network(SupportedChains.BERACHAIN_MAINNET).require(ContractType.UniswapV3Factory),
}

// export const FACTORY_ADDRESS = '0x802b2f0c1F647d54B2Eaa212Df75bf069eb218Ab'

export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000'

// export const POOL_INIT_CODE_HASH = '0x701582da8b11337ef1e7537e21bffb5a7f8241261b4e459cae6b306cda28e619' //old mumbai
// export const POOL_INIT_CODE_HASH = '0xdc0752429cdeae99bd54b3d560562d3b09dc5c6fb016614d6f7080b8a597f6b2' // bera priv
// export const POOL_INIT_CODE_HASH = '0x23a76033cea7bc2cf418a69d940f429c5d5f5bd29c6930a928d45c92443ec709';

export const POOL_INIT_CODE_HASH: AddressMap = {
  [SupportedChains.POLYGON_MUMBAI]: AddressManager.network(SupportedChains.POLYGON_MUMBAI).require(
    ContractType.InitHashCodeV3
  ),
  [SupportedChains.BERACHAIN_PRIVATE_TESTNET]: AddressManager.network(
    SupportedChains.BERACHAIN_PRIVATE_TESTNET
  ).require(ContractType.InitHashCodeV3),
  [SupportedChains.BERACHAIN_ARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_ARTIO_TESTNET).require(
    ContractType.InitHashCodeV3
  ),
  [SupportedChains.BERACHAIN_BARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_BARTIO_TESTNET).require(
    ContractType.InitHashCodeV3
  )
  // [SupportedChains.BERACHAIN_MAINNET]: AddressManager.network(SupportedChains.BERACHAIN_MAINNET).require(ContractType.UniswapV2Factory),
}
/**
 * The default factory enabled fee amounts, denominated in hundredths of bips.
 */
export enum FeeAmount {
  LOWEST = 100,
  LOW = 500,
  MEDIUM = 3000,
  HIGH = 10000,
  EXTRA = 20000
}

/**
 * The default factory tick spacings by fee amount.
 */
export const TICK_SPACINGS: { [amount in FeeAmount]: number } = {
  [FeeAmount.LOWEST]: 1,
  [FeeAmount.LOW]: 10,
  [FeeAmount.MEDIUM]: 60,
  [FeeAmount.HIGH]: 200,
  [FeeAmount.EXTRA]: 400
}
