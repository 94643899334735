import { ChainId } from './chains'

import AddressManager, { ContractType, SupportedChains, SupportedChainsValues } from '@kodiak-finance/address-manager'

export const KODIAK_FACTORY_ADDRESS = '0x802b2f0c1F647d54B2Eaa212Df75bf069eb218Ab'
export const KODIAK_V2_FACTORY_ADDRESS = '0x3eB4a791f61c795aAc73Bb45C4Aa9d0ad2dba7Ce'
export const KODIAK_SWAP_ROUTER_ADDRESS = '0xDeaCcd5728d9DE339c80b3c976852d0eCa488bd3'
export const KODIAK_SWAP_ROUTER_02_ADDRESS = '0x36e53Ecec9E1295817fBcec6C905f0363A658Bd0'
export const KODIAK_NONFUNGIBLE_ADDRESS = '0x9F6e76157AC9090Bc4e2835aB0b6FF15d748aDac'
export const KODIAK_QUOTER_ADDRESS = '0x58e18D8E85403c986A0bd8CCCCC7EE5Be4C8D5F3'
export const V2_ROUTER_ADDRESS = '0x22704908fb549921beeab77aca4d2bf044e9958e'

type AddressMap = { [chainId: number]: string }

type ChainAddresses = {
  v3CoreFactoryAddress: string
  multicallAddress: string
  quoterAddress: string
  v3MigratorAddress?: string
  nonfungiblePositionManagerAddress?: string
  tickLensAddress?: string
  swapRouter02Address?: string
  v1MixedRouteQuoterAddress?: string
}

const DEFAULT_NETWORKS = [ChainId.POLYGON, ChainId.POLYGON_MUMBAI]

function constructSameAddressMap(address: string, additionalNetworks: ChainId[] = []): AddressMap {
  return DEFAULT_NETWORKS.concat(additionalNetworks).reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = address
    return memo
  }, {})
}

export const UNI_ADDRESSES: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984', [
  ChainId.OPTIMISM,
  ChainId.ARBITRUM_ONE,
  ChainId.SEPOLIA
])

export const KODIAK_NFT_AIRDROP_CLAIM_ADDRESS = '0x8B799381ac40b838BBA4131ffB26197C432AFe78'

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// BERA MIGRATION: Uncomment the necessary lines following to enable the bera addresses once they're added to @kodiak-finance/address-manager
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const V2_FACTORY_ADDRESSES: AddressMap = {
         [ChainId.POLYGON_MUMBAI]: AddressManager.network(SupportedChains.POLYGON_MUMBAI).require(
           ContractType.UniswapV2Factory
         ),
         [ChainId.BERACHAIN_PRIVATE_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_PRIVATE_TESTNET).require(
           ContractType.UniswapV2Factory
         ),
         [ChainId.BERACHAIN_ARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_ARTIO_TESTNET).require(
           ContractType.UniswapV2Factory
         ),
         [ChainId.BERACHAIN_BARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_BARTIO_TESTNET).require(
           ContractType.UniswapV2Factory
         )

         // [ChainId.BERACHAIN_MAINNET]: AddressManager.network(SupportedChains.BERACHAIN_MAINNET).require(ContractType.UniswapV2Factory),
       }

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
         [ChainId.POLYGON_MUMBAI]: AddressManager.network(SupportedChains.POLYGON_MUMBAI).require(
           ContractType.UniswapV3Factory
         ),
         [ChainId.BERACHAIN_PRIVATE_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_PRIVATE_TESTNET).require(
           ContractType.UniswapV3Factory
         ),
         [ChainId.BERACHAIN_ARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_ARTIO_TESTNET).require(
           ContractType.UniswapV3Factory
         ),
         [ChainId.BERACHAIN_BARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_BARTIO_TESTNET).require(
           ContractType.UniswapV3Factory
         )
         // [ChainId.BERACHAIN_MAINNET]: AddressManager.network(SupportedChains.BERACHAIN_MAINNET).require(ContractType.UniswapV3Factory),
       }

export const V2_ROUTER_ADDRESSES: AddressMap = {
         [ChainId.POLYGON_MUMBAI]: AddressManager.network(SupportedChains.POLYGON_MUMBAI).require(
           ContractType.UniswapV2Router02
         ),
         [ChainId.BERACHAIN_PRIVATE_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_PRIVATE_TESTNET).require(
           ContractType.UniswapV2Router02
         ),
         [ChainId.BERACHAIN_ARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_ARTIO_TESTNET).require(
           ContractType.UniswapV2Router02
         ),
         [ChainId.BERACHAIN_BARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_BARTIO_TESTNET).require(
           ContractType.UniswapV2Router02
         )
         // [ChainId.BERACHAIN_MAINNET]: AddressManager.network(SupportedChains.BERACHAIN_MAINNET).require(ContractType.UniswapV2Router02),
       }

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
         [ChainId.POLYGON_MUMBAI]: AddressManager.network(SupportedChains.POLYGON_MUMBAI).require(
           ContractType.NonfungiblePositionManager
         ),
         [ChainId.BERACHAIN_PRIVATE_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_PRIVATE_TESTNET).require(
           ContractType.NonfungiblePositionManager
         ),
         [ChainId.BERACHAIN_ARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_ARTIO_TESTNET).require(
           ContractType.NonfungiblePositionManager
         ),
         [ChainId.BERACHAIN_BARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_BARTIO_TESTNET).require(
           ContractType.NonfungiblePositionManager
         )
         // [ChainId.BERACHAIN_MAINNET]: AddressManager.network(SupportedChains.BERACHAIN_MAINNET).require(ContractType.NonfungiblePositionManager),
       }

export const SWAP_ROUTER_02_ADDRESSES: AddressMap = {
         [ChainId.POLYGON_MUMBAI]: AddressManager.network(SupportedChains.POLYGON_MUMBAI).require(
           ContractType.SwapRouter02
         ),
         [ChainId.BERACHAIN_PRIVATE_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_PRIVATE_TESTNET).require(
           ContractType.SwapRouter02
         ),
         [ChainId.BERACHAIN_ARTIO_TESTNET]: AddressManager.network(ChainId.BERACHAIN_ARTIO_TESTNET).require(
           ContractType.SwapRouter02
         ),
         [ChainId.BERACHAIN_BARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_BARTIO_TESTNET).require(
           ContractType.SwapRouter02
         )
         // [ChainId.BERACHAIN_MAINNET]: AddressManager.network(ChainId.BERACHAIN_MAINNET).require(ContractType.SwapRouter02),
       }

export const MULTICALL_ADDRESSES: AddressMap = {
         [ChainId.POLYGON_MUMBAI]: AddressManager.network(SupportedChains.POLYGON_MUMBAI).require(
           ContractType.Multicall
         ),
         [ChainId.BERACHAIN_PRIVATE_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_PRIVATE_TESTNET).require(
           ContractType.Multicall
         ),
         [ChainId.BERACHAIN_ARTIO_TESTNET]: AddressManager.network(ChainId.BERACHAIN_ARTIO_TESTNET).require(
           ContractType.Multicall
         ),
         [ChainId.BERACHAIN_BARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_BARTIO_TESTNET).require(
           ContractType.Multicall
         )
         // [ChainId.BERACHAIN_MAINNET]: AddressManager.network(ChainId.BERACHAIN_MAINNET).require(ContractType.Multicall),
       }

export const QUOTER_ADDRESSES: AddressMap = {
         [ChainId.POLYGON_MUMBAI]: AddressManager.network(SupportedChains.POLYGON_MUMBAI).require(
           ContractType.QuoterV2
         ),
         [ChainId.BERACHAIN_PRIVATE_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_PRIVATE_TESTNET).require(
           ContractType.QuoterV2
         ),
         [ChainId.BERACHAIN_ARTIO_TESTNET]: AddressManager.network(ChainId.BERACHAIN_ARTIO_TESTNET).require(
           ContractType.QuoterV2
         ),
         [ChainId.BERACHAIN_BARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_BARTIO_TESTNET).require(
           ContractType.QuoterV2
         )
         // [ChainId.BERACHAIN_MAINNET]: AddressManager.network(ChainId.BERACHAIN_MAINNET).require(ContractType.QuoterV2),
       }

export const MIXED_ROUTE_QUOTER_V1_ADDRESSES: AddressMap = {
         [ChainId.POLYGON_MUMBAI]: AddressManager.network(SupportedChains.POLYGON_MUMBAI).require(
           ContractType.MixedRouteQuoterV1
         ),
         [ChainId.BERACHAIN_PRIVATE_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_PRIVATE_TESTNET).require(
           ContractType.MixedRouteQuoterV1
         ),
         [ChainId.BERACHAIN_ARTIO_TESTNET]: AddressManager.network(ChainId.BERACHAIN_ARTIO_TESTNET).require(
           ContractType.MixedRouteQuoterV1
         ),
         [ChainId.BERACHAIN_BARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_BARTIO_TESTNET).require(
           ContractType.MixedRouteQuoterV1
         )
         // [ChainId.BERACHAIN_MAINNET]: AddressManager.network(ChainId.BERACHAIN_MAINNET).require(ContractType.MixedRouteQuoterV1),
       }

export const TICK_LENS_ADDRESSES: AddressMap = {
         [ChainId.POLYGON_MUMBAI]: AddressManager.network(SupportedChains.POLYGON_MUMBAI).require(
           ContractType.TickLens
         ),
         [ChainId.BERACHAIN_PRIVATE_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_PRIVATE_TESTNET).require(
           ContractType.TickLens
         ),
         [ChainId.BERACHAIN_ARTIO_TESTNET]: AddressManager.network(ChainId.BERACHAIN_ARTIO_TESTNET).require(
           ContractType.TickLens
         ),
         [ChainId.BERACHAIN_BARTIO_TESTNET]: AddressManager.network(SupportedChains.BERACHAIN_BARTIO_TESTNET).require(
           ContractType.TickLens
         )
         // [ChainId.BERACHAIN_MAINNET]: AddressManager.network(ChainId.BERACHAIN_MAINNET).require(ContractType.TickLens),
       }

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
    // SupportedChainId.ARBITRUM_ONE,
    // SupportedChainId.ARBITRUM_RINKEBY,
    // SupportedChainId.POLYGON,
    ChainId.POLYGON_MUMBAI
  ])
}

// Networks that share most of the same addresses i.e. Mainnet, Goerli, Optimism, Arbitrum, Polygon
const DEFAULT_ADDRESSES: ChainAddresses = {
  v3CoreFactoryAddress: V3_CORE_FACTORY_ADDRESSES[ChainId.POLYGON_MUMBAI],
  multicallAddress: MULTICALL_ADDRESSES[ChainId.POLYGON_MUMBAI],
  quoterAddress: QUOTER_ADDRESSES[ChainId.POLYGON_MUMBAI],
  nonfungiblePositionManagerAddress: NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[ChainId.POLYGON_MUMBAI],
  v3MigratorAddress: '0xA5644E29708357803b5A882D272c41cC0dF92B34'
}

const POLYGON_ADDRESSES: ChainAddresses = DEFAULT_ADDRESSES

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// BERA MIGRATION: Uncomment the necessary lines here to enable the bera addresses
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

const BERA_PRIVATE_TESTNET_ADDRESSES: ChainAddresses = {
  v3CoreFactoryAddress: V3_CORE_FACTORY_ADDRESSES[ChainId.BERACHAIN_PRIVATE_TESTNET],
  multicallAddress: MULTICALL_ADDRESSES[ChainId.BERACHAIN_PRIVATE_TESTNET],
  quoterAddress: QUOTER_ADDRESSES[ChainId.BERACHAIN_PRIVATE_TESTNET],
  nonfungiblePositionManagerAddress: NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[ChainId.BERACHAIN_PRIVATE_TESTNET]
}

const BERA_ARTIO_TESTNET_ADDRESSES: ChainAddresses = {
  v3CoreFactoryAddress: V3_CORE_FACTORY_ADDRESSES[ChainId.BERACHAIN_ARTIO_TESTNET],
  multicallAddress: MULTICALL_ADDRESSES[ChainId.BERACHAIN_ARTIO_TESTNET],
  quoterAddress: QUOTER_ADDRESSES[ChainId.BERACHAIN_ARTIO_TESTNET],
  nonfungiblePositionManagerAddress: NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[ChainId.BERACHAIN_ARTIO_TESTNET]
}

const BERA_BARTIO_TESTNET_ADDRESSES: ChainAddresses = {
  v3CoreFactoryAddress: V3_CORE_FACTORY_ADDRESSES[ChainId.BERACHAIN_ARTIO_TESTNET],
  multicallAddress: MULTICALL_ADDRESSES[ChainId.BERACHAIN_ARTIO_TESTNET],
  quoterAddress: QUOTER_ADDRESSES[ChainId.BERACHAIN_ARTIO_TESTNET],
  nonfungiblePositionManagerAddress: NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[ChainId.BERACHAIN_ARTIO_TESTNET]
}

// const BERA_MAINNET_ADDRESSES: ChainAddresses = {
//   v3CoreFactoryAddress: V3_CORE_FACTORY_ADDRESSES[ChainId.BERACHAIN_MAINNET],
//   multicallAddress: MULTICALL_ADDRESSES[ChainId.BERACHAIN_MAINNET],
//   quoterAddress: QUOTER_ADDRESSES[ChainId.BERACHAIN_MAINNET],
//   nonfungiblePositionManagerAddress: NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[ChainId.BERACHAIN_MAINNET]
// }

export const CHAIN_TO_ADDRESSES_MAP: Record<SupportedChainsValues, ChainAddresses> = {
         // [ChainId.POLYGON]: POLYGON_ADDRESSES,
         [ChainId.POLYGON_MUMBAI]: POLYGON_ADDRESSES,
         [ChainId.BERACHAIN_PRIVATE_TESTNET]: BERA_PRIVATE_TESTNET_ADDRESSES,
         [ChainId.BERACHAIN_ARTIO_TESTNET]: BERA_ARTIO_TESTNET_ADDRESSES,
         [ChainId.BERACHAIN_BARTIO_TESTNET]: BERA_BARTIO_TESTNET_ADDRESSES
         // [ChainId.BERACHAIN_MAINNET]: BERA_MAINNET_ADDRESSES
       }

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [ChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6'
}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  [ChainId.MAINNET]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3'
}

export const TIMELOCK_ADDRESSES: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [ChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e'
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [ChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8'
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e')
}

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [ChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd'
}
